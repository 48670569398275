.loader-container {
  display: inline;
  &:before {
    content: '';
    background-image: url("../../assets/img/tail-spin.svg");
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: -20px;
    left: -24px;
    top: 4px;
    position: relative;
    box-sizing: border-box;
    margin-top: -16px;
  }
}