.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 60px;
  padding-top: 11px;
  padding-bottom: 9px;

  .footerContainer {
    display: flex;
    align-items: center;
    align-content: center;
  }
  .allRights {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 12px;
    padding-top: 5px;
    color: rgba(0, 0, 0, 0.6);

  }
  .footerLogo {
    float: left;
    width: 84px;
    height: 16.45px;
    left: 84px;
    top: 1332px;

    opacity: 0.4;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }

}