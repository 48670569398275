$breakpoint-tablet: 840px;

.productivity {
  .dashboardHeader-first-child {
    margin-top: 28px;
  }
}
.recordsContainer-wrapper {
  background: white;
  width: 100%;
  min-width: 860px;
  display: block;
  @media (max-width: $breakpoint-tablet) {
    min-width: auto;
  }
}
.recordsContainer {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  width: 1160px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: "Noto Sans", sans-serif;
}
.recordHeader {
  font-size: 14px;
  font-weight: bold;
  color: rgba(0,0,0,.8);
  border-bottom: 1px solid rgba(0,0,0,.5);
  padding-bottom: 5px;
}
.recordRecord {
  font-size: 14px;
  font-weight: normal;
  color: rgba(0,0,0,.8);
  border-bottom: 1px solid rgba(0,0,0,.2);
  padding-top: 20px;
  padding-bottom: 10px;
}
.chartWrapper {
  width: 100%;
  //overflow-x: scroll;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-button {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0);
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  :hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .2);
  }
  ::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, .2);
  }
  ::-webkit-scrollbar-track {
    background: rgba(255,255,255,0);
    border: 0px none rgba(255,255,255,0);
    border-radius: 12px;
  }
  ::-webkit-scrollbar-track:hover {
    background: rgba(0, 0, 0, .1);
  }
  ::-webkit-scrollbar-track:active {
    background: rgba(0, 0, 0, .1);
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  scrollbar-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  &:hover {
    scrollbar-color: rgba(0, 0, 0, .2) rgba(0, 0, 0, 0);
  }
  scrollbar-width: 4px;
}
.chartContainer {
  padding-top: 0px;
  background: white;
  width: auto !important;
  max-width: 1160px;
  //height: 240px !important;
  position: relative;
  overflow-x: scroll;

  @media (max-width: $breakpoint-tablet) {

  }
  .chartBody {
    //width: 1160px;
    display: flex;
    flex-direction: row;

  }
  canvas {
    width: 100%!important;
    height: 300px !important;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: white;
   // position: absolute;
    left: 0;
    top: 0;
    pointer-events:none;

  }
}

.modalHeader {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 20px;
}
.modalSubHeader {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 10px;
  margin-top: 30px;
}
.modalControl {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}
.modalSubmit {
  height: 48px;
  background: rgb(51, 113, 195);
  border-radius: 32px;
  color: white;
  box-sizing: border-box;
  padding: 20px 60px;
  cursor: pointer;
  box-shadow: none;
  border: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 2px;
  width: 100%;
  text-align: center;
}
.modalLinkButton {
  color: rgba(0,0,0,.3);
  font-family: "Noto Sans", sans-serif;
  font-size: 15px;
  display: block;
  height: 48px;
  padding: 20px 60px;
  padding-right: 0;
  box-sizing: border-box;
  line-height: 2px;
  text-decoration: none;
  font-weight: 500;
}
.MuiDataGrid-root {
  font-family: 'Noto Sans', sans-serif !important;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-button {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0);
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  :hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .2);
  }
  ::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, .2);
  }
  ::-webkit-scrollbar-track {
    background: rgba(255,255,255,0);
    border: 0px none rgba(255,255,255,0);
    border-radius: 12px;
  }
  ::-webkit-scrollbar-track:hover {
    background: rgba(0, 0, 0, .1);
  }
  ::-webkit-scrollbar-track:active {
    background: rgba(0, 0, 0, .1);
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  scrollbar-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  &:hover {
    scrollbar-color: rgba(0, 0, 0, .2) rgba(0, 0, 0, 0);
  }
  scrollbar-width: 4px;
  .dataGridImage {
    width: 24px;
    height: 24px;
  }
  .MuiDataGrid-cell {
    border-bottom: 1px solid rgba(0,0,0,.05) !important;
  }
  .MuiDataGrid-columnHeaderTitle {
    text-transform: uppercase !important;
    font-size: 12px  !important;
    font-weight: 600 !important;
    color: rgba(0,0,0,.5);
  }
  .MuiDataGrid-row {
    .MuiDataGrid-cell:first-child {
      border-bottom: 1px solid rgba(0,0,0,0) !important;
    }
  }
}
.counterExtender {
  position: relative;
  width: 100%;
  margin-bottom: -40px;
}
.welcomeAction {

  font-family: 'Roboto Mono', monospace;
  width: 100%;
  height: 300px;
  //background-image: url("../../assets/img/graph-bg.png");
  background-size: cover;
  padding-top: 50px;
  box-sizing: border-box;
  @media (max-width: $breakpoint-tablet) {
    width: 100%;
    padding-top: 40px;
  }
  .loginContainer {
    background: white;
    color: black;
    width: 600px;
    max-width: 100%;
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 3%);
    border-radius: 6px;
    padding: 30px 36px;
    @media (max-width: $breakpoint-tablet) {
      width: 100%;

    }
    .loginHeader {
      font-family: 'Roboto Mono', monospace;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 28px;
      text-align: center;
    }
    .loginInputContainer {
      width: 100%;
      margin-top: 4px;
      margin-bottom: 4px;
    }
    .loginButton {
      height: 44px;
      background: rgb(51, 113, 195);
      border-radius: 32px;
      color: white;
      box-sizing: border-box;
      padding: 19px 48px;
      cursor: pointer;
      box-shadow: none;
      border: none;
      font-family: "Noto Sans", sans-serif;
      font-family: 'Roboto Mono', monospace;
      font-size: 14px;
      font-weight: bold;
      line-height: 5px;
      width: 100%;
      text-align: center;
      margin-top: 10px;
      text-decoration: none;
      max-width: 260px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}