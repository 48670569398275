.downloadContainer {
  text-align: center;
  padding-top: 40px;
}
.downloadImage {
  width: 80px;
  padding-top: 30px;
  &:hover {
    opacity: .8;
  }
}