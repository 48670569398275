@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;0,800;1,400&display=swap');
.loginScreen {
  width: 100%;
  height: 80vh;
  display: block;
  margin: 0;
  box-sizing: border-box;
  padding-top: 20vh;
}
.loginContainer {
  background: white;
  color: black;
  width: 320px;
  max-width: 100%;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  padding: 40px 40px;
}
.loginLogo {
  width: 320px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
  img {
    width: 100px;
    opacity: .4;
  }
}
.loginHeader {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  line-height: 26px;

  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 20px;

}
.loginInputContainer {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  input {
    width: 100%;
  }
}
.loginButton {
  height: 48px;
  background: rgba(51, 113, 195, 1);
  border-radius: 32px;
  color: white;
  box-sizing: border-box;
  padding: 20px 60px;
  cursor: pointer;
  box-shadow: none;
  border: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 2px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  display: block;
  &:hover {
    background: rgb(25, 102, 206);
  }
}