@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;0,800;1,400&family=Noto+Sans:ital,wght@0,100;0,400;0,500;0,600;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap');
@import "./variables";
body {

}
html {
  scroll-behavior: smooth;
}
.bg {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  background: #F3F5F7;
  @media (max-width: $breakpoint-tablet) {
    overflow-x: hidden;
  }
}
.mg {
  margin: 0;
  background: #fff;
  scroll-behavior: smooth;
  font-family: 'Roboto Mono', monospace;
  @media (max-width: $breakpoint-tablet) {
    overflow-x: hidden;
  }
}
.dashboardHeader {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 15px;
  margin-top: 40px;

  //font-family: "Montserrat", sans-serif;
  //font-style: normal;
  //font-weight: 700;
  //font-size: 12px;
  //line-height: 30px;
  //display: flex;
  //align-items: center;
  //color: rgb(0 0 0 / 70%);
  //margin-bottom: 2px;
  //margin-top: 40px;
  //text-transform: uppercase;
  //-webkit-text-stroke: 1px rgb(0 0 0 / 11%);
  //text-shadow: 0px 0px 1px rgb(0 0 0 / 11%);
}
.dashboardContainer {
  width: 1160px;
  max-width: 90%;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

}
.formAfterMessage {
  font-weight: 400;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: center;
  padding-top: 20px;
  color: rgba(0,0,0,.8);
  a {
    text-decoration: none;
    color: rgb(51, 113, 195);;
    font-weight: 700;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    &:hover {
      color: rgb(25, 102, 206);
    }
  }
}
.loginScreen {
  width: 100%;
  height: 80vh;
  display: block;
  margin: 0;
  box-sizing: border-box;
  padding-top: 20vh;
}
.loginContainer {
  background: white;
  color: black;
  width: 320px;
  max-width: 100%;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  padding: 40px 40px;
}
.loginLogo {
  width: 320px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
  img {
    width: 100px;
    opacity: .4;
  }
}
.loginHeader {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  line-height: 26px;

  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 20px;

}
.loginInputContainer {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  input {
    width: 100%;
  }
}
.loginButton {
  height: 48px;
  background: rgba(51, 113, 195, 1);
  border-radius: 32px;
  color: white;
  box-sizing: border-box;
  padding: 20px 60px;
  cursor: pointer;
  box-shadow: none;
  border: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 7px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  text-decoration: none;
  &:hover {
    background: rgb(25, 102, 206);
  }
}
.formOr {
  font-family: 'Noto Sans', sans-serif;
  font-size: 15px;
  color: rgba(0,0,0,.7);
  text-align: center;
  padding-top: 7px;
}
.loginLink {
  text-decoration: none;
  color: rgb(51, 113, 195);
  font-weight: 700;
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
}
.welcomeContainer {
  width: auto;
  min-width: auto;
  max-width: initial;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: $breakpoint-tablet) {
    width: 90%;
    min-width: auto;
    max-width: 90%;
    padding-bottom: 60px;
  }
  .loginLogo {
    width: 100px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    img {
      width: 100px;
      opacity: .7;
    }
  }
  .welcome {
    .welcomeHeader {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 37px;
      display: flex;
      color: #000000;
      padding-top: 8px;
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
      p {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 22px;
        color: rgba(0,0,0,.7);
      }
      span {
        font-weight: 600;
        font-size: 15px;
        line-height: 26px;
        color: rgba(0,0,0,.8);
        margin-bottom: -10px;
        padding-top: 20px;
      }
    }
    .welcomeActionContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: $breakpoint-tablet) {
        flex-direction: column;
      }
      .welcomeFeatures {
        width: 53%;
        padding-top: 26px;
        @media (max-width: $breakpoint-tablet) {
          width: 100%;
        }
        .welcomeFeature {
          display: flex;
          align-content: center;
          align-items: center;
          margin-bottom: 24px;
          .welcomeFeature-img {
            width: 40px;
            height: 40px;
            margin-right: 14px;
            flex-shrink: 0;
            img {
              width: 100%;
            }
          }
          .welcomeFeature-content {
            .welcomeFeatureHeader {
              font-family: 'Roboto Mono', monospace;
              font-style: normal;
              font-weight: 700;
              font-size: 15px;
              line-height: 16px;

              color: rgba(0, 0, 0, 0.8);

            }
            .welcomeFeatureText {
              font-family: 'Montserrat', sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              line-height: 22px;
              padding-top: 6px;
              color: #000000;
            }
          }
        }
      }
      .welcomeDivider {
        height: 200px;
        width: 1px;
        margin-left: 20px;
        margin-right: 30px;
        border-left: 1px solid rgba(0,0,0,0.1);
        @media (max-width: $breakpoint-tablet) {
          display: none;
        }
      }
      .welcomeAction {
          width: 44%;
        @media (max-width: $breakpoint-tablet) {
          width: 100%;
          padding-top: 40px;
        }
        .loginContainer {
          background: white;
          color: black;
          width: 320px;
          max-width: 100%;
          box-sizing: border-box;
          margin-right: auto;
          margin-left: auto;
          box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 3%);
          border-radius: 6px;
          padding: 30px 36px;
          @media (max-width: $breakpoint-tablet) {
            width: 100%;

          }
          .loginHeader {
            font-family: "Noto Sans", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            color: rgba(0, 0, 0, 0.8);
            margin-bottom: 28px;
            text-align: center;
          }
          .loginInputContainer {
            width: 100%;
            margin-top: 4px;
            margin-bottom: 4px;
          }
          .loginButton {
            height: 44px;
            background: rgb(51, 113, 195);
            border-radius: 32px;
            color: white;
            box-sizing: border-box;
            padding: 19px 48px;
            cursor: pointer;
            box-shadow: none;
            border: none;
            font-family: "Noto Sans", sans-serif;
            font-size: 14px;
            font-weight: bold;
            line-height: 5px;
            width: 100%;
            text-align: center;
            margin-top: 10px;
            text-decoration: none;
          }
        }
      }
    }
  }
}